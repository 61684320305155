<template>
  <div id="app">
    <navbar></navbar>
    <main class="main">
      <router-view />
    </main>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import Navbar from './components/navbar.vue'
import FooterBar from './components/footerbar.vue'

export default {
  components: {
    Navbar,
    FooterBar
  }
}
</script>
<style lang="scss">
#app {
  color: #1d2130;
  min-width: 1200px;
  .main {
    max-width: 1200px;
    margin: 0 auto;
  }
}
</style>
