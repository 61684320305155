import axios from 'axios'
import { Message } from 'element-ui'

/* 请求配置 */
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
})

/* 请求前拦截 */
service.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
}, error => {
  Message.error({ message: '请求超时!' })
  return Promise.reject(error)
})

/* 响应拦截 */
// service.interceptors.response.use(response => {
//   const res = response.data
//   if (!res.success) {
//     Message.error(res.msg || { message: '系统错误' })
//     return Promise.reject(new Error('Error'))
//   } else {
//     return res
//   }
// }, error => {
//   return Promise.reject(error)
// })

export default service
