<template>
  <div class="major">
    <h2 v-if="!this.$route.query.id">热门专业</h2>
    <el-row>
      <el-col :span="24" v-for="(item, index) in schoolList" :key="item.id">
        <el-card
          shadow="never"
          :body-style="{
            padding: '0px',
          }"
        >
          <div class="header">
            <h4>{{ item.homeTitle }}</h4>
            <h4>{{ item.descTitle }}</h4>
          </div>
          <el-menu mode="horizontal" text-color="#212121">
            <el-menu-item
              v-for="majorItem in majorList[index]"
              :key="majorItem.id"
              @click="goMajorItem(majorItem)"
            >
              <div class="icon">
                <img src="/img/zy_icon.png" />
              </div>
              <div class="text">{{ majorItem.majorName }}</div>
            </el-menu-item>
          </el-menu>
            <p>开设专业以及收费标准以广东省教育考试院公布的成人高考专业目录为准</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { schoolPage, majorList, schoolInfo } from '../../api'
export default {
  data () {
    return {
      queryInfo: {
        id: this.$route.query.id,
        currentPage: 0,
        keyword: '',
        pageSize: 10
      },
      schoolList: [],
      majorList: []
    }
  },
  created () {
    this.getSchoolList()
  },
  watch: {
    $route () {
      this.getSchoolList()
    }
  },
  methods: {
    getSchoolList () {
      // 单个学校的专业
      if (this.$route.query.id) {
        this.schoolList = []
        this.majorList = []
        this.queryInfo.id = this.$route.query.id

        schoolInfo(this.queryInfo.id)
          .then((res) => {
            this.schoolList[0] = res.data.data
          })
          .then(() => {
            this.schoolList.map((item, index) => {
              this.getMajorList(item, index)
              this.$forceUpdate()
            })
          })
      } else {
        // 全部学校的专业
        schoolPage(this.queryInfo)
          .then((res) => {
            this.schoolList = res.data.data.data
          })
          .then(() => {
            this.schoolList.map((item, index) => {
              this.getMajorList(item, index)
            })
          })
      }
    },
    getMajorList (item, index) {
      majorList(item.id).then((res) => {
        this.$set(this.majorList, index, res.data.data)
      })
    },
    // 跳转专业详情
    goMajorItem (item) {
      this.$router.push({
        path: '/majorItem',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.major {
  padding: 70px 0px;
  background-color: $color-Background;
  .header {
    background-image: url("/img/footer_bg.jpg");
    background-repeat: no-repeat;
    h4 {
      color: white;
    }
  }
  .icon {
    display: inline-block;
    width: 35px;
    margin: 5px;
    height: 100%;
    img {
      width: 100%;
    }
  }
  .text {
    display: inline-block;
  }
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
  background-color: $color-Background;
  > .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    background-color: $color-Background;
    color: $color-hover;
    font-weight: 800;
  }
}

.el-card__body,
.el-main {
  padding: 0px;
}
.el-card {
  border: none;
  margin-bottom: 30px;
}

.el-menu-item {
  width: 25%;
  height: 65px;
  border-bottom: none;
  font-size: 16px;
  color: #212121;
}
p {
  text-align: center;
  padding: 16px 0px;
  background-color: $color-Background;
}
</style>
