<template>
  <div class="home">
    <div class="tableBar">
      <ul>
        <li
          :style="{ backgroundColor: item.color }"
          v-for="(item, index) in list"
          :key="index"
          @click="itemClick(item)"
        >
          <a :style="{ backgroundImage: item.img }">{{ item.label }}</a>
        </li>
      </ul>
    </div>
    <div class="customer">
      <h2>学历提升方案</h2>
      <ul>
        <li v-for="(item, index) in customerList" :key="index">
          <a>{{ item }}</a>
          <p @click="jump">立即咨询</p>
        </li>
      </ul>
    </div>
    <school></school>
    <majors></majors>
    <message></message>
    <div class="img" @click="jump">
      <div></div>
      <div></div>
    </div>
    <question></question>
  </div>
</template>

<script>
import message from '@/components/home/message.vue'
import school from '@/components/home/school.vue'
import majors from '@/components/home/majors.vue'
import question from '@/components/home/question.vue'
export default {
  components: {
    school,
    majors,
    question,
    message
  },
  data () {
    return {
      list: [
        {
          label: '热门专业',
          path: '/major',
          img: "url('/img/tableBar/indmenu1.png')",
          color: '#e73333'
        },
        {
          label: '报考条件',
          path: '/signup/info',
          img: "url('/img/tableBar/indmenu2.png')",
          color: '#0ba778'
        },
        {
          label: '报考流程',
          path: '/signup/process',
          img: "url('/img/tableBar/indmenu3.png')",
          color: '#32afdd'
        },
        {
          label: '报考费用',
          path: '/signup/expense',
          img: "url('/img/tableBar/indmenu4.png')",
          color: '#ff9508'
        },
        {
          label: '立即报名',
          path: '/major',
          img: "url('/img/tableBar/indmenu5.png')",
          color: '#ff6600'
        }
      ],
      customerList: ['低基础升大专', '高中升大专', '专科升本科']
    }
  },
  methods: {
    itemClick (item) {
      const curPath = this.$route.fullPath
      if (curPath.includes(item.path)) return
      this.$router.push(item.path)
    },
    jump () {
      this.$router.push('/qr')
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  margin: 0 auto;
  .tableBar {
    box-sizing: border-box;
    height: 275px;
    padding: 70px 0px;
    ul {
      display: flex;
      height: 135px;
      justify-content: space-between;

      li {
        width: 227px;
        cursor: pointer;
        border-radius: 8px;
        text-align: center;
        font-size: 20px;
        &:hover {
          opacity: 0.3;
        }
        a {
          background: no-repeat center 20px;
          padding-top: 93px;
          display: block;
          color: white;
          height: 42px;
        }
      }
    }
  }
  .customer {
    background-color: #fff9f1;
    height: 402px;
    box-sizing: border-box;
    padding: 70px 0px;
    ul {
      display: flex;
      height: 142px;
      margin-top: 60px;
      justify-content: space-between;
      li {
        width: 380px;
        background: #ff9508;
        line-height: 94px;
        border-radius: 10px;
        font-size: 25px;
        a {
          display: block;
          width: 100%;
          text-align: center;
          color: #fff;
        }
        p {
          height: 48px;
          line-height: 48px;
          background: #2d2d2d;
          color: #ff9508;
          font-size: 16px;
          text-align: center;
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
  }
  .img {
    height: 600px;
    div {
      background-repeat: no-repeat;
      background-size: contain;
    }
    & div:first-child {
      height: 163px;
      background-image: url("/img/ad1.jpg");
      background-repeat: no-repeat;
    }
    & div:last-child {
      height: 700px;
      background-image: url("/img/ad2.jpg");
      background-repeat: no-repeat;
    }
  }
}
</style>
