<template>
  <div class="message">
    <h2>学历提升的优势</h2>
    <el-row :gutter="30">
      <el-col :span="8" v-for="(item, index) in imageUrl" :key="index">
        <el-card
          shadow="never"
          :body-style="{
            display: 'flex',
            height: '164px',
            padding: '0px',
            margin: '10px 0px',
          }"
        >
          <div class="img">
            <img @click="jump" :src="item.url" />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imageUrl: [
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/szjx.jpg'
        },
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/kzgz.jpg'
        },
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/dwts.jpg'
        },
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/kgwy.jpg'
        },
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/jflh.jpg'
        },
        {
          url: 'https://minio-test.cx-online.net/ndjy/images/qzjy.jpg'
        }
      ]
    }
  },
  methods: {
    jump () {
      this.$router.push('/qr')
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  padding: 70px 0px;
}
.el-card {
  border: none;
}
</style>
