<template>
  <div class="school">
    <h2>院校推荐</h2>
    <el-row :gutter="40">
      <el-col :span="12" v-for="(item, index) in schoolList" :key="index">
        <el-card
          :body-style="{
            display: 'flex',
            height: '268px',
          }"
        >
          <div class="img">
            <img :src="`${url}${item.pic}`" alt="" />
          </div>
          <div class="text">
            <h4>{{ item.schoolName }}</h4>
            <span @click="goSchoolItem(item)"
              >查看详细
            </span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { host } from '@/service/url.js'
import { schoolPage } from '../../api'
export default {
  data () {
    return {
      queryInfo: {
        currentPage: 0,
        keyword: '',
        pageSize: 10
      },
      schoolList: [],
      url: `${host}`
    }
  },
  created () {
    this.getSchoolList()
  },
  methods: {
    getSchoolList () {
      schoolPage(this.queryInfo).then((res) => {
        this.schoolList = res.data.data.data
      })
    },
    goSchoolItem (item) {
      this.$router.push({
        path: '/schoolItem',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.school {
  text-align: center;
  box-sizing: border-box;
  padding: 70px 0px;
}
.el-card__body,
.el-main {
  padding: 0px;
}
.el-card {
  .img {
    flex: 1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      color: #fff;
      background-color: #ff9508;
      border-radius: 15px;
      padding: 0.5em 1em;
      margin-top: 0.5em;
    }
  }
}
</style>
