import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../components/info/about.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/adUniExam',
    name: 'AdUniExam',
    component: () => import('../components/info/adUniExam.vue'),
    meta: {
      title: '成人高考'
    }
  },
  {
    path: '/school',
    name: 'School',
    component: () => import('../components/home/school.vue'),
    meta: {
      title: '院校推荐'
    }
  },
  {
    path: '/major',
    name: 'Major',
    component: () => import('../components/home/majors.vue'),
    meta: {
      title: '热门专业'
    }
  },
  {
    path: '/signup/info',
    name: 'SignupInfo',
    component: () => import('../components/info/signup/info.vue'),
    meta: {
      title: '报考条件'
    }
  },
  {
    path: '/signup/process',
    name: 'SignupProecess',
    component: () => import('../components/info/signup/process.vue'),
    meta: {
      title: '报考流程'
    }
  },
  {
    path: '/signup/expense',
    name: 'SignupExpense',
    component: () => import('../components/info/signup/expense.vue'),
    meta: {
      title: '报考费用'
    }
  },
  {
    path: '/qr',
    name: 'qr',
    component: () => import('../components/qr.vue'),
    meta: {
      title: '小程序码'
    }
  },
  {
    path: '/schoolItem',
    name: 'SchoolItem',
    component: () => import('../components/home/schoolItem.vue'),
    meta: {
      title: '学院详情'
    }
  },
  {
    path: '/majorItem',
    name: 'MajorItem',
    component: () => import('../components/home/majorItem.vue'),
    meta: {
      title: '专业详情'
    }
  }

]

const router = new VueRouter({
  routes
})

export default router
