<template>
  <div class="question">
    <h2>常见问题</h2>
    <el-divider></el-divider>
    <div v-for="(item, index) in question" :key="index">
      <div class="card">
        <div class="img"></div>
        <div class="text">
          <span class="ques">{{ item.title }}</span>
          <p class="answer">{{ item.description }}</p>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      question: [
        {
          description:
            '答：成考网上报名时间9月中旬截止报名（具体根据报名院校名额决定，名额满了会提前截止），考试时间为每年的10月底进行。',
          title: '成考何时报名?'
        },
        {
          title:
            '脱产、函授和业余的学习形式所发毕业证书有区别吗？脱产和业余的毕业证书效力有区别吗？',
          description:
            '答：证书样式有区别，所颁发的毕业证书相应会标注脱产、函授和业余的字样。脱产和业余的毕业证书效力没有区别，区别仅在于其学习形式不同。'
        },
        {
          title: '成人高考通过率高吗？',
          description:
            '答：成人高考的通过率其实是很高的，由于考试本身就不难，通过率基本上是90%左右，因为成人高考的考试题目相对于比较简单，一般考试科目为语文、数学、政治和英语，总分加在一起450分左右，而且成人高考的录取分数线较低，一般学校120分左右就会录取。'
        },
        {
          title: '什么是成人高考？',
          description:
            '答：成人高考是为我国各类成人高等学校选拔合格的毕业生以进入更高层次学历教育的入学考试。'
        },
        {
          title: '专升本考试中专业课考什么科目？',
          description:
            '答：专升本考试统考科目均为三门，其中，两门公共课为政治、英语，一门专业基础课根据招生专业所隶属的学科门类确定。（1）文史（含外语类）、中医类：政治、英语、大学语文;（2）艺术类：政治、英语、艺术概论;（3）理工、经济管理类：政治、英语、高数（二）;（4）法学：政治、英语、民法;（5）教育学类:政治、英语、教育理论;（6）农学类：政治、英语、生态学基础;（7）医学类(中医学类、药学类等两个一级学科除外)：政治、英语、医学综合。'
        },
        {
          title: '成人高考入学考试考什么？',
          description:
            '答：成人高考有高升专、高起本、专升本三个学历层次，不同学历层次，考试科目是不一样的：高升专的考试科目有3门，分别是语文、数学、英语；专升本的考试科目有3门，分别是政治、英语、专业课；高起本的考试科目有4门，分别是语文、数学、英语、史地/理化。'
        },
        {
          title: '成人高考学历国家承认吗？',
          description:
            '答：成人高考属于我国国民教育系列，获得的学历是国家承认，学信网可查的，可用于考公务员、考研、评职称、出国留学、考资格证、积分入户等。'
        },
        {
          title: '成人高考毕业证有用吗？含金量高吗？',
          description:
            '答：成人高考毕业证的含金量是不容怀疑的，成人高考毕业证是与其它国家承认的大学专（本）毕业证书具有同等效力，含金量一样，在使用上也是相同的。'
        },
        {
          title: '成人高考怎么取得学士学位?',
          description:
            '答：成人高考考生本科毕业后可以按照《中华人民共和国学位条例》、《国务院学位委员会关于授予成人高等教育本科毕业生学士学位暂行规定》的要求，参加省学位办的学士学位课程考试，成绩合格者可申报相关专业的学士学位。'
        },
        {
          title: '成人高考准考证什么时候打印？',
          description:
            '答：成人高考准考证打印/领取时间一般是考前一周左右，具体准考证打印时间需关注各地省教育考试院通知。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.question {
  padding: 70px 0px;
}

.el-divider--horizontal {
  margin: 0px;
}

.card {
  width: 100%;
  // min-height: 77px;
  display: flex;
  padding: 40px 0px;
  flex-direction: row;
  .img {
    min-width: 77px;
    min-height: 77px;
    margin: 0px 40px;
    background-image: url("/img/icon.jpg");
    background-repeat: no-repeat;
  }
  .text {
    span {
      color: #ff9508;
      font-size: 18px;
      font-weight: bold;
      display: block;
      padding-bottom: 10px;
      line-height: 25px;
    }
    p {
      line-height: 30px;
    }
  }
}
</style>
