<template>
  <div class="navbar-container">
    <div class="headerTop">
      <div>
        <a>广东升学服务平台</a>
        <a>资深教育12年通过率全省领先</a>
      </div>
    </div>
    <div class="navbar">
      <div class="navbar__left" v-if="false"></div>
      <div class="navbar__right">
        <el-menu
          router
          class="el-menu-demo"
          mode="horizontal"
          text-color="#000"
        >
          <el-menu-item index="/home">主页</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
          <el-submenu index="major">
            <template slot="title">
              <router-link tag="label" to="/major">热门专业</router-link>
            </template>

            <el-menu-item
              v-for="item in this.schoolList"
              :key="item.id"
              :index="`/major?id=${item.id}`"
              >{{ item.homeTitle }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item index="/adUniExam">成人高考</el-menu-item>
          <el-submenu index="signup">
            <template slot="title">报考须知</template>
            <el-menu-item index="/signup/info">报考条件</el-menu-item>
            <el-menu-item index="/signup/process">报考流程</el-menu-item>
            <el-menu-item index="/signup/expense">报考费用</el-menu-item>
          </el-submenu>

          <el-menu-item index="/school">院校推荐</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="headerImg"></div>
  </div>
</template>

<script>
import { schoolPage } from '../api'
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        // return [
        //   {
        //     label: '主页',
        //     path: '/home'
        //   },
        //   {
        //     label: '关于我们',
        //     path: '/about'
        //   },
        //   {
        //     label: '热门专业',
        //     path: '/major'
        //   },
        //   {
        //     label: '成人高考',
        //     path: '/adUniExam'
        //   },
        //   {
        //     label: '报考须知',
        //     path: '/signup/info',
        //     flag: false,
        //     children: [
        //       { label: '报考条件', path: '/signup/info' },
        //       { label: '报考流程', path: '/signup/process' },
        //       { label: '报考费用', path: '/signup/expense' }
        //     ]
        //   },
        //   {
        //     label: '院校推荐',
        //     path: '/school'
        //   }
        // ]
      }
    }
  },
  data () {
    return {
      queryInfo: {
        currentPage: 0,
        keyword: '',
        pageSize: 10
      },
      schoolList: []
    }
  },
  created () {
    this.getSchoolList()
  },
  methods: {
    getSchoolList () {
      schoolPage(this.queryInfo).then((res) => {
        const list = res.data.data.data
        list.map((item) => {
          this.schoolList.push({
            id: item.id,
            homeTitle: item.homeTitle
          })
        })
      })
    },
    linkHandle (item) {
      const curPath = this.$route.fullPath
      if (curPath.includes(item.path)) return
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-container {
  .headerTop {
    color: white;
    background-color: #000;
    height: 45px;
    line-height: 45px;
    text-align: right;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    div {
      width: $minHeight;
      margin: auto;
      a:first-child {
        padding-right: 17px;
        border-right: 1px solid #4a4a4a;
      }
      a:last-child {
        color: #ff9528;
        padding-left: 17px;
      }
    }
  }
  .navbar {
    font-weight: bold;
    $height: 122px;
    width: $minHeight;
    margin: auto;
    display: flex;
    align-items: center;
    height: 122px;

    &__left {
      width: 274px;
      height: 92px;
      margin: auto, 0px;
      background-image: url("/img/logo.jpg");
      background-repeat: no-repeat;
    }

    &__right {
      flex: 1;
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          min-width: 100px;
          cursor: pointer;
          a {
            &:hover {
              color: $color-hover;
            }
          }
        }
      }
    }
  }
  .headerImg {
    width: 100%;
    height: 552px;
    background-image: url("/img/banner1.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }
  .el-menu-item,
  ::v-deep.el-submenu__title {
    font-size: 16px !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-submenu__title:hover {
    background-color: $color-navBackground !important;
  }
  .el-menu-item:hover {
    background-color: $color-navBackground !important;
    color: white !important;
  }
  ::v-deep.el-menu--horizontal {
    .el-menu-item.is-active {
      border-bottom: none;
    }
    .el-submenu__title {
      border-bottom: none !important;
    }
  }
}
</style>
