import request from '@/service/request'

// 获取学校分页信息
const schoolPage = (params) => {
  return request.post('/server/school/page', params)
}
// 根据学校id获取学校信息
const schoolInfo = (id) => {
  return request.post('/server/school/get/' + id)
}
// 根据学校id获取专业列表
const majorList = (params) => {
  return request.post('/server/school/getMajor/' + params)
}
// 根据专业id获取专业信息
const majorInfo = (params) => {
  return request.post('/server/major/get/' + params)
}

export {
  schoolPage,
  majorList,
  majorInfo,
  schoolInfo
}
