<template>
  <div class="footer-container">
    <div class="footer">
      <hr/>
      <div class="info">
        <p>总部：江门市江海区五邑路683号广东南方职业学院校内</p>
        <p>联系电话：0750-3073057</p>
        <p>蓬江分部：江门市蓬江区东华一路68号银隆大厦501</p>
        <p>联系电话：0750-3963456</p>
      </div>
      <div class="beian">
        <p>
          此网站信息最终解释权属于广东成学在线科技有限公司&nbsp;&nbsp;
          <a href="https://beian.miit.gov.cn/">粤ICP备2021165252号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  .info {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 32px 0px;
    width: $minHeight;
    height: 208px;
    margin: auto;
    text-align: left;
    p{
      margin: 0px 0px 21px 0px;
    }

  }
  .footer {
    .beian {
      color: #9a9a9a;
      background-color: #2d2d2d;
      padding: 0 100px;
      text-align: center;
      padding: 32px 0px;
      a {
        color: #ccc;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
